import gql from 'graphql-tag'

export const LIST_SYSTEM_USER = gql`
    query($name: String, $profile: UserProfile) {
        users(name: $name, profile: $profile) {
            id
            name
            lastName
            motherLastName
            email
            profile
            status
            notification
            globalNotification
            manageNotification
            managementGlobalNotification
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
