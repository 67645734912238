import gql from 'graphql-tag'

export const LIST_CONTACT = gql`
    query($name: String, $phone: String, $status: ContactStatus) {
        contacts(name: $name, phone: $phone, status: $status) {
            id
            name
            lastName
            motherLastName
            email
            phone
            status
            area
            groups {
                id
                name
                platform
                type
                isWhatsapp
                numberEvents
            }
        }
    }
`
