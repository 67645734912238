<script>
import {defineComponent} from 'vue';
import VueMultiselect from "vue-multiselect";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {has, values} from "lodash";
import formatGraphQLErrors from "@/errors/formatGraphQLErrors";
import {notification} from "@/helpers/notifications";
import {GENERAL_REPORT} from "@/graphql/queries/report/generalReport";
import {SYSTEM_USER_REPORT} from "@/graphql/queries/report/reportBySystemUser";
import {GROUP_REPORT} from "@/graphql/queries/report/reportByGroup";
import {TOTAL_GROUP_SYSTEM_USER_REPORT} from "@/graphql/queries/report/totalGroupSystemUsersReport";
import {LIST_SYSTEM_USER} from "@/graphql/queries/systemUsers/list";
import {LIST_GROUP} from "@/graphql/queries/groups/list";
import {LIST_CONTACT} from "@/graphql/queries/contacts/list";
import {CONTACTS_AND_SYSTEM_USERS_REPORT} from "@/graphql/queries/report/reportContactsAndSystemUsers";
import {CUSTOM_REPORT} from "@/graphql/queries/report/customReport";
import {APP_MOBILE_REPORT} from "@/graphql/queries/report/appMobileReport";
import {getProfileName} from "@/helpers/helper";

export default defineComponent({
  name: "Reports",
  data: () => ({
    error: null,
    submitted: false,
    options: [
      {
        id: 1,
        name: 'General'
      },
      {
        id: 2,
        name: 'Por Usuarios'
      },
      {
        id: 3,
        name: 'Por Grupos'
      },
      {
        id: 4,
        name: 'Total Usuarios Grupo'
      }
    ],
    operatingSystem: [
      {
        id: 1,
        name: 'iOS'
      },
      {
        id: 2,
        name: 'Android'
      },
    ],
    groups: [],
    users: [],
    contacts: [],
    selectedOption: {
      id: 1,
      name: 'General'
    },
    selectedGroup: null,
    selectedGroupCustomReport: null,
    selectedUser: null,
    selectedOperatingSystem: {
      id: 1,
      name: 'iOS'
    },
    defaultDateConfig: {
      dateFormat: "Y-m-d",
      defaultDate: null,
    },
    formGroupReport: {
      id: null,
      startDate: null,
      endDate: null,
    },
    formCustomReport: {
      id: null,
      groupId: false,
      totalUsers: false,
      contactsName: false,
      createdAt: false
    },
    formAppReport: {
      operatingSystem: 'iOS',
      useOperatingSystem: false,
      firstDownloads: false,
      startDate: null,
      endDate: null,
    },
    showDropdownGroup: false,
    showDropdownUser: false,
    ShowInputDates: true,
    allUsers: [],
    searchQuery: null,
    page: 1,
    perPage: 50,
    pages: [],
  }),
  setup: () => ({v$: useVuelidate()}),
  validations() {
    if (this.selectedOption?.id === 1) {
      return {
        formGroupReport: {
          startDate: {
            required: helpers.withMessage("La fecha de inicio es requerida", required),
          },
          endDate: {
            required: helpers.withMessage("La fecha final es requerida", required),
          },
        },
      }
    } else if ([2, 3].includes(this.selectedOption?.id)) {
      return {
        formGroupReport: {
          id: {
            required: helpers.withMessage("Debes de seleccionar una opción", required),
          },
          startDate: {
            required: helpers.withMessage("La fecha de inicio es requerida", required),
          },
          endDate: {
            required: helpers.withMessage("La fecha final es requerida", required),
          },
        },
      }
    } else if (this.selectedOption?.id === 4) {
      return {
        formGroupReport: {
          id: {
            required: helpers.withMessage("Debes de seleccionar una opción", required),
          },
        },
      }
    }
  },
  async mounted() {
    await this.fetchSystemUsers();
    await this.fetchContacts();
    await this.fetchGroups();
  },
  components: {
    VueMultiselect,
    flatPickr
  },
  created() {
    this.setPages();
  },
  methods: {
    getProfileName,
    fullName ({ name, lastName }) {
      return `${name} ${lastName}`
    },
    async fetchSystemUsers() {
      const {data} = await this.$apollo.query({
        query: LIST_SYSTEM_USER,
        fetchPolicy: "network-only",
      });

      this.users = data.users;
      this.allUsers = data.users;
    },
    async fetchContacts() {
      const {data} = await this.$apollo.query({
        query: LIST_CONTACT,
        fetchPolicy: "network-only",
      });

      this.contacts = data.contacts;
      this.allUsers = [...this.allUsers, ...data.contacts];
    },
    async fetchGroups() {
      const {data} = await this.$apollo.query({
        query: LIST_GROUP,
        fetchPolicy: "network-only",
      });

      this.groups = data.groups;
    },
    async generateGroupReporte() {
      if (this.selectedOption.id === 1) {
        await this.report({
          query: GENERAL_REPORT,
          fetchPolicy: "network-only",
          variables: {
            input: {
              startDate: `${this.formGroupReport.startDate} 00:00:00`,
              endDate: `${this.formGroupReport.endDate} 23:59:00`
            }
          }
        }, true);
      }

      if ([2, 3].includes(this.selectedOption.id)) {
        await this.report({
          query: this.selectedOption.id === 2 ? SYSTEM_USER_REPORT : GROUP_REPORT,
          fetchPolicy: "network-only",
          variables: {
            input: {
              id: this.formGroupReport.id,
              startDate: `${this.formGroupReport.startDate} 00:00:00`,
              endDate: `${this.formGroupReport.endDate} 23:59:00`
            }
          }
        }, true);
      }

      if (this.selectedOption.id === 4) {
        await this.report({
          query: TOTAL_GROUP_SYSTEM_USER_REPORT,
          fetchPolicy: "network-only",
          variables: {
            id: this.formGroupReport.id,
          }
        }, true);
      }

    },
    async generateReportContactsAndSystemUsers(){
      await this.report({
        query: CONTACTS_AND_SYSTEM_USERS_REPORT,
        fetchPolicy: "network-only"
      }, false);
    },
    async generateCustomReport() {
      if (this.formCustomReport.id === null || this.formCustomReport.id === undefined) {
        notification('warning', 'Debes de seleccionar un grupo')
      } else {
        await this.report({
          query: CUSTOM_REPORT,
          fetchPolicy: "network-only",
          variables: {
            input: this.formCustomReport
          }
        }, false);
      }
    },
    async generateAppReport() {
      if (!this.formAppReport.useOperatingSystem && this.formAppReport.firstDownloads) {
        notification('warning', 'Debes de seleccionar un opción de Tipo de Reporte');
      } else if (!this.formAppReport.startDate || !this.formAppReport.endDate) {
        notification('warning', 'Debes de seleccionar un rango de fecha');
      } else {
        await this.report({
          query: APP_MOBILE_REPORT,
          fetchPolicy: "network-only",
          variables: {
            input: {
              operatingSystem: this.formAppReport.operatingSystem,
              useOperatingSystem: this.formAppReport.useOperatingSystem,
              firstDownloads: this.formAppReport.firstDownloads,
              startDate: `${this.formAppReport.startDate} 00:00:00`,
              endDate: `${this.formAppReport.endDate} 23:59:00`,
            }
          }
        }, false);
      }
    },
    async report(queryOptions, validate) {
      try {

        if(validate) {
          this.submitted = true;
          this.v$.$touch();

          if (this.v$.$invalid) {
            return false;
          }
        }

        await this.$apollo.query(queryOptions);

        notification('success', 'El reporte se esta generando. Actualiza tu listado de Reportes');

      } catch (errors) {
        let dataError = has(errors, 'graphQLErrors') ? formatGraphQLErrors(errors.graphQLErrors) : errors.message

        if (has(dataError, 'category') && dataError.category === 'validation') {
          dataError = values(dataError.errors);
        }

        if (has(dataError, 'category') && dataError.category === 'user') {
          dataError = dataError.message
        }

        if (has(dataError, 'category') && dataError.category === 'graphql') {
          dataError = dataError.message
        }

        this.error = dataError;
        notification('error' , dataError);
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.allUsers?.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(contacts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return contacts?.slice(from, to);
    }
  },
  computed: {
    displayedAllUsers() {
      return this.paginate(this.allUsers);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedAllUsers.filter((data) => {
          return (
              data?.name?.toLowerCase().includes(search) ||
              data?.lastName?.toLowerCase().includes(search) ||
              data?.motherLastName?.toLowerCase().includes(search) ||
              data?.email?.toLowerCase().includes(search) ||
              data?.profile?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedAllUsers;
      }
    },
  },
  watch: {
    selectedOption: function (option) {
      this.selectedUser = null;
      this.selectedGroup = null;

      if (option?.id === 2) {
        this.showDropdownGroup = false;
        this.showDropdownUser = true
        this.ShowInputDates = true
      } else if (option?.id === 3) {
        this.showDropdownGroup = true;
        this.showDropdownUser = false
        this.ShowInputDates = true
      } else if (option?.id === 4) {
        this.showDropdownGroup = true;
        this.showDropdownUser = false
        this.ShowInputDates = false
      } else {
        this.showDropdownGroup = false;
        this.showDropdownUser = false
        this.ShowInputDates = true
      }
    },
    selectedUser: function (value) {
      this.formGroupReport.id = null;

      if (value?.id) {
        this.formGroupReport.id = value?.id;
      }
    },
    selectedGroup: function (value) {
      this.formGroupReport.id = null;

      if (value?.id) {
        this.formGroupReport.id = value?.id;
      }
    },
    selectedGroupCustomReport: function (value) {
      this.formCustomReport.id = null;

      if (value?.id) {
        this.formCustomReport.id = value?.id;
      }
    },
    'formCustomReport.totalUsers': function (value) {
      this.formCustomReport.totalUsers = value;

      this.formCustomReport.contactsName = !value;

    },
    'formCustomReport.contactsName': function (value) {
      this.formCustomReport.contactsName = value;

      this.formCustomReport.totalUsers = !value;
    },
    'formAppReport.useOperatingSystem': function (value) {
      this.formAppReport.useOperatingSystem = value;

      this.formAppReport.firstDownloads = !value;
    },
    'formAppReport.firstDownloads': function (value) {
      this.formAppReport.firstDownloads = value;

      this.formAppReport.useOperatingSystem = !value;
    },
    selectedOperatingSystem: function(value) {
      if(value) {
        this.formAppReport.operatingSystem = value.name;
      }
    },
    allUsers() {
      this.setPages();
    },
  }
});
</script>

<template>
  <b-card-body class="p-4 centered">
    <div class="tab-content">

      <div class="tab-pane active" id="groupReport" role="tabpanel">
        <form
            autocomplete="off"
            class="needs-validation"
            @submit.prevent="generateGroupReporte"
        >
          <b-row>
            <b-col lg="12 mb-4">
              <div class="flex-shrink-0">
                <b-button
                    type="submit"
                    variant="customRed"
                >
                  Generar Reporte
                </b-button>
              </div>
            </b-col>

            <b-col lg="12">
              <b-col lg="6">
                <div class="mb-3">
                  <label class="form-label">Seleccione un tipo de Reporte</label>
                  <VueMultiselect
                      class="align-multiselect"
                      v-model="selectedOption"
                      placeholder="Seleccione un tipo de Reporte"
                      :options="options"
                      :allow-empty="false"
                      track-by="name"
                      label="name"
                  />
                </div>
              </b-col>
            </b-col>

            <b-col
                lg="12"
                v-if="showDropdownGroup"
            >
              <b-col lg="6">
                <div class="mb-3">
                  <label class="form-label">Seleccione un Grupo</label>
                  <VueMultiselect
                      class="align-multiselect"
                      v-model="selectedGroupCustomReport"
                      placeholder="Seleccione un grupo"
                      :options="groups"
                      :allow-empty="false"
                      track-by="name"
                      label="name"
                      :class="{
                        'is-invalid': submitted && v$.formGroupReport.id.$error,
                      }"
                  />
                </div>

                <div v-for="(item, index) in v$.formGroupReport.id.$errors" :key="index" class="invalid-feedback d-flex">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>

              </b-col>
            </b-col>

            <b-col
                lg="12"
                v-if="showDropdownUser"
            >
              <b-col lg="6">
                <div class="mb-3">
                  <label class="form-label">Seleccione un Usuario</label>
                  <VueMultiselect
                      class="align-multiselect"
                      v-model="selectedUser"
                      placeholder="Seleccione un usuario"
                      :options="users"
                      :allow-empty="false"
                      :custom-label="fullName"
                      track-by="name"
                      label="name"
                      :class="{
                        'is-invalid': submitted && v$.formGroupReport.id.$error,
                      }"
                  />

                  <div v-for="(item, index) in v$.formGroupReport.id.$errors" :key="index" class="invalid-feedback d-flex">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>

                </div>
              </b-col>

            </b-col>

            <b-col
                lg="6"
                v-if="ShowInputDates"
            >
              <div class="mb-3">
                <label class="form-label mb-0">Fecha Inicio</label>
                <flat-pickr
                    v-model="formGroupReport.startDate"
                    :config="defaultDateConfig"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.formGroupReport.startDate.$error,
                    }"
                >
                </flat-pickr>
              </div>

              <div v-for="(item, index) in v$.formGroupReport.startDate.$errors" :key="index" class="invalid-feedback d-flex">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </b-col>

            <b-col
                lg="6"
                v-if="ShowInputDates"
            >
              <div class="mb-3">
                <label class="form-label mb-0">Fecha Final</label>
                <flat-pickr
                    v-model="formGroupReport.endDate"
                    :config="defaultDateConfig"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.formGroupReport.endDate.$error,
                    }"
                >
                </flat-pickr>
              </div>

              <div v-for="(item, index) in v$.formGroupReport.endDate.$errors" :key="index" class="invalid-feedback d-flex">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </b-col>

            <b-col lg="12">
              <div class="invalid-feedback" v-if="error">
                <span >{{ error }}</span>
              </div>
            </b-col>

          </b-row>
        </form>
      </div>








      <div class="tab-pane" id="userReport" role="tabpanel">
        <b-col lg="12">
          <b-card no-body>
            <b-card-header>
              <b-row class="g-4 align-items-center">

                <b-col sm="auto">
                  <div class="flex-shrink-0">
<!--                    <button-->
<!--                        type="button"-->
<!--                        class="btn btn-sm btn-outline-danger text-dark fs-14"-->
<!--                        @click.prevent="generateReportContactsAndSystemUsers"-->
<!--                    >-->
<!--                      Generar Reporte-->
<!--                    </button>-->

                    <b-button
                        type="button"
                        variant="customRed"
                        @click.prevent="generateReportContactsAndSystemUsers"
                    >
                      Generar Reporte
                    </b-button>

                  </div>
                </b-col>

                <b-col sm>
                  <div class="d-flex justify-content-sm-end">
                    <div class="search-box ms-2">
                      <input type="text" class="form-control" id="searchResultList"
                             placeholder="Buscar..." v-model="searchQuery">
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body>
              <div id="recomended-jobs" class="table-card">
                <div role="complementary" class="gridjs gridjs-container" style="width: 100%;">
                  <div class="gridjs-wrapper" style="height: auto;">
                    <table role="grid" class="gridjs-table" style="height: auto;">
                      <thead class="gridjs-thead">
                      <tr class="gridjs-tr">
                        <th data-column-id="position" class="gridjs-th gridjs-th-sort" tabindex="0">
                          <div class="gridjs-th-content">Nombre</div>
                        </th>
                        <th data-column-id="companyName" class="gridjs-th gridjs-th-sort"
                            tabindex="0">
                          <div class="gridjs-th-content">Usuario</div>
                        </th>
                        <th data-column-id="location" class="gridjs-th gridjs-th-sort" tabindex="0">
                          <div class="gridjs-th-content">Tipo de Usuario</div>
                        </th>
                      </tr>
                      </thead>
                      <tbody class="gridjs-tbody">
                      <tr class="gridjs-tr" v-for="(data, index) of resultQuery" :key="index">
                        <td data-column-id="position" class="gridjs-td">{{ data?.name }} {{ data?.lastName }}</td>
                        <td data-column-id="location" class="gridjs-td">{{ data.email }}</td>
                        <td data-column-id="salary" class="gridjs-td">{{ getProfileName(data?.profile) }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="gridjs-footer">
                    <div class="gridjs-pagination">
                      <div role="status" aria-live="polite" class="gridjs-summary" title="Page 1 of 3">
                        Mostrando <b>{{ page }}</b> de
                        <b>{{ resultQuery?.length }}</b> de
                        <b>{{ allUsers?.length }}</b>
                        resultado
                      </div>
                      <div class="gridjs-pages">
                        <button tabindex="0" role="button" title="Previous" @click="page--"
                                v-if="page !== 1">Anterior
                        </button>

                        <button tabindex="0" role="button" class="gridjs"
                                :class="{ active: pageNumber === page, disabled: pageNumber === '...', }"
                                v-for="(pageNumber, index) in pages" :key="index"
                                @click="page = pageNumber">{{ pageNumber }}
                        </button>

                        <button tabindex="0" role="button" title="Next" @click="page++"
                                v-if="page < pages.length">Siguiente
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="gridjs-temp" class="gridjs-temp"></div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </div>


      <div class="tab-pane" id="customReport" role="tabpanel">
        <form
            autocomplete="off"
            class="needs-validation"
            @submit.prevent="generateCustomReport"
        >
          <b-row>
            <b-col lg="12 mb-4">
              <div class="flex-shrink-0">
<!--                <button type="submit" class="btn bt-sm btn-outline-danger text-dark fs-14">-->
<!--                  Generar Reporte-->
<!--                </button>-->

                <b-button
                    type="submit"
                    variant="customRed"
                >
                  Generar Reporte
                </b-button>
              </div>
            </b-col>

            <b-col lg="12">
              <b-col
                  lg="8"
              >
                <b-col lg="8">
                  <div class="mb-3">
                    <label class="form-label">Seleccione un Grupo</label>
                    <VueMultiselect
                        class="align-multiselect"
                        v-model="selectedGroupCustomReport"
                        placeholder="Seleccione un grupo"
                        :options="groups"
                        track-by="name"
                        label="name"
                    />
                  </div>
                </b-col>
              </b-col>
            </b-col>

            <b-col
                lg="12"
            >
              <b-col lg="6">
                <div class="mb-3">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="customReportGroupId"
                        v-model="formCustomReport.groupId"
                    >

                    <label class="form-check-label" for="customReportGroupId">
                      Id de Grupo
                      <span class="badge badge-label bg-dark"><i class="mdi mdi-circle-medium"></i> Incluir en Reporte</span>
                    </label>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="customReportOption" readonly>

                    <label class="form-check-label" for="customReportOption">
                      Personas que están en el grupo
                      <span class="badge badge-label bg-dark"><i class="mdi mdi-circle-medium"></i> Incluir en Reporte</span>
                    </label>

                    <div class="mr-3">
                      <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="customReportTotalUsers"
                            name="optionCustomReport"
                            v-model="formCustomReport.totalUsers"
                        >
                        <label class="form-check-label" for="customReportTotalUsers">
                          Cantidad de Personas
                        </label>
                      </div>

                      <div class="form-check mb-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            name="optionCustomReport"
                            id="customReportContactsName"
                            v-model="formCustomReport.contactsName"
                        >
                        <label class="form-check-label" for="customReportContactsName">
                          Nombre de Personas
                        </label>
                      </div>
                    </div>

                  </div>

                </div>

                <div class="mb-3">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="customReportCreatedAt"
                        v-model="formCustomReport.createdAt"
                    >
                    <label class="form-check-label" for="customReportCreatedAt">
                      Fecha creación
                      <span class="badge badge-label bg-dark"><i class="mdi mdi-circle-medium"></i> Incluir en Reporte</span>
                    </label>
                  </div>
                </div>
              </b-col>
            </b-col>


          </b-row>
        </form>
      </div>


      <div class="tab-pane" id="appReport" role="tabpanel">
        <form
            autocomplete="off"
            class="needs-validation"
            @submit.prevent="generateAppReport"
        >
          <b-row>
            <b-col lg="12 mb-4">
              <div class="flex-shrink-0">
<!--                <button type="submit" class="btn bt-sm btn-outline-danger text-dark fs-14">-->
<!--                  Generar Reporte-->
<!--                </button>-->

                <b-button
                    type="submit"
                    variant="customRed"
                >
                  Generar Reporte
                </b-button>

              </div>
            </b-col>

            <b-col lg="12">
              <b-col lg="6">
                <div class="mb-3">
                  <label class="form-label">Sistema Operativo</label>
                  <VueMultiselect
                      class="align-multiselect"
                      v-model="selectedOperatingSystem"
                      placeholder="Sistema Operativo"
                      :options="operatingSystem"
                      :allow-empty="false"
                      track-by="name"
                      label="name"
                  />
                </div>
              </b-col>
            </b-col>

            <b-col lg="12">
              <div class="mb-3">
                <label class="form-label">Tipo de Reporte</label>

                <div class="form-check">

                  <div class="mr-3">
                    <div class="form-check mb-2">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="optionAppReportSystem"
                          v-model="formAppReport.useOperatingSystem"
                      >
                      <label class="form-check-label" for="amountPeople">
                        Uso por sistema operativo
                      </label>
                    </div>

                    <div class="form-check mb-2">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          name="optionAppReportSystem"
                          v-model="formAppReport.firstDownloads"
                      >
                      <label class="form-check-label" for="namePeople">
                        Primeras descargas
                      </label>
                    </div>
                  </div>

                </div>


              </div>
            </b-col>

            <b-col
                lg="6"
            >
              <div class="mb-3">
                <label class="form-label mb-0">Fecha Inicio</label>
                <flat-pickr
                    v-model="formAppReport.startDate"
                    :config="defaultDateConfig"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.formAppReport.startDate.$error,
                    }"
                >
                </flat-pickr>

                <div v-for="(item, index) in v$.formAppReport?.startDate.$errors" :key="index" class="invalid-feedback d-flex">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
            </b-col>

            <b-col
                lg="6"
                v-if="ShowInputDates"
            >
              <div class="mb-3">
                <label class="form-label mb-0">Fecha Final</label>
                <flat-pickr
                    v-model="formAppReport.endDate"
                    :config="defaultDateConfig"
                    class="form-control"
                    :class="{
                      'is-invalid': submitted && v$.formAppReport.endDate.$error,
                    }"
                >
                </flat-pickr>

                <div v-for="(item, index) in v$.formAppReport?.endDate.$errors" :key="index" class="invalid-feedback d-flex">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
            </b-col>

          </b-row>
        </form>
      </div>
    </div>
  </b-card-body>
</template>


<style lang="scss">
.align-multiselect {
  display: inline-grid;
  right: 15px;
}
</style>
