<script>
import {defineComponent} from 'vue';
import GenerateReport from "@/views/report/generate-reporte.vue";

export default defineComponent({
  name: "ContainerReports",
  components: {
    GenerateReport
  }
});
</script>

<template>
  <GenerateReport/>
</template>
