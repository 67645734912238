import gql from 'graphql-tag'

export const LIST_REPORT = gql`
    query($message: String, $fileName: String) {
        reports(message: $message, fileName: $fileName) {
            id
            fileUrl
            message
            fileName
            systemUser {
                id
                name
                lastName
                motherLastName
                email
                profile
                status
                notification
                globalNotification
                manageNotification
                managementGlobalNotification
            }
            createdAt
        }
    }
`
