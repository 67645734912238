import gql from 'graphql-tag'

export const LIST_GROUP = gql`
    query($name: String, $platform: String, $type: GroupType) {
        groups(name: $name, platform: $platform, type: $type) {
            id
            name
            platform
            type
            isWhatsapp
            numberEvents
            contacts {
                id
                name
                lastName
                motherLastName
                email
                phone
                status
                area
            }
        }
    }
`
