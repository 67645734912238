<script>
import {defineComponent} from 'vue';
import {authComputed} from "@/state/helpers";
import Reports from "@/views/report/reports.vue";
import {LIST_REPORT} from "@/graphql/queries/report/list";
import {createdAtFormat} from "@/helpers/helper";


export default defineComponent({
  name: "GenerateReport",
  components: {
    Reports
  },
  data: () => ({
    reports: [],
  }),
  apollo: {
    reports: {
      query: LIST_REPORT,
      fetchPolicy: "network-only",
      update: (data) => {
        return {...data.reports};
      },
      loadingKey: 'loading'
    },
  },
  methods: {
    createdAtFormat,
    refreshReports() {
      this.$apollo.queries.reports.refresh();
    }
  },
  computed: {
    ...authComputed,
  }
});
</script>

<template>
  <div>
    <b-row class="mt-n4">
      <b-col lg="12">
        <b-card no-body class="mt-n4 mx-n0 mb-n0">
          <div class="bg-black">
            <b-card-body class="pb-3 mb-0">
              <b-row>
                <b-col md>
                  <b-row class="align-items-center">
                    <b-col md="auto">
                      <div class="avatar-md mb-md-0 mb-4">
                        <div class="avatar-title bg-white rounded-circle">
                          <div class="avatar-title bg-red-custom rounded-circle">
                            <i class="bx bxs-report text-white" style="font-size: 45px"></i>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col md>
                      <h4 class="fw-semibold text-white">
                        Generar Reportes
                      </h4>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-card-body>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xxl="12">
        <b-card no-body>
          <b-card-header>
            <ul class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              " role="tablist">

              <li class="nav-item">
                <b-link class="nav-link text-body active" data-bs-toggle="tab" href="#groupReport" role="tab">
                  <i class="fas fa-home"></i>
                  Reporte de Grupos
                </b-link>
              </li>

              <li class="nav-item">
                <b-link class="nav-link text-body" data-bs-toggle="tab" href="#userReport" role="tab">
                  <i class="far fa-user"></i>
                  Reporte de Usuarios
                </b-link>
              </li>

              <li class="nav-item">
                <b-link class="nav-link text-body" data-bs-toggle="tab" href="#customReport" role="tab">
                  <i class="far fa-envelope"></i>
                  Reporte Personalizado
                </b-link>
              </li>

              <li class="nav-item">
                <b-link class="nav-link text-body" data-bs-toggle="tab" href="#appReport" role="tab">
                  <i class="far fa-envelope"></i>
                  Reporte Aplicativo
                </b-link>
              </li>
            </ul>
          </b-card-header>

          <Reports/>

        </b-card>
      </b-col>

      <b-col xxl="12">
        <b-card no-body>
          <b-card-body>
            <div class="d-flex align-items-center mb-4">
              <div class="flex-grow-1">
                <h5 class="card-title mb-0">Listado de Reporte</h5>
              </div>
              <div class="flex-shrink-0">
                <b-link
                    class="badge bg-light text-primary fs-14"
                    @click.prevent="refreshReports"
                >
                  <i class="ri-refresh-line"></i>
                </b-link>
              </div>
            </div>


            <div data-simplebar style="max-height: 615px;">
              <ul class="list-group">
                <li
                    class="list-group-item"
                    v-for="(report, key) in reports"
                    :key="key"
                >
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <div class="d-flex">

                        <div class="flex-shrink-0 avatar-xs">
                          <div class="avatar-title bg-dark bg-opacity-10 text-danger rounded">
                            <i class="ri-file-list-line"></i>
                          </div>
                        </div>

                        <div class="flex-shrink-0 ms-2">
                          <h6 class="fs-14 mb-0">{{ report.fileName }}</h6>
                          <small class="text-muted">
                            {{ createdAtFormat(report.createdAt) }}
                          </small>
                          <br>
                          <small class="text-muted">
                            Generado por: {{ report.systemUser?.name }} {{ report.systemUser?.lastName }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="flex-shrink-0">
                      <b-link
                          :href="report.fileUrl"
                          class="btn btn-sm btn-outline-danger"
                      >
                        <i class="ri-download-cloud-2-fill"></i>
                      </b-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

